import { createTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import primary from './colors/primary';
import secondary from './colors/secondary';

export const theme = {
  palette: {
    type: 'light',
    primary: {
      light: primary[500],
      main: primary[700],
      dark: primary[900],
    },
    secondary,
    success: {
      light: green[300],
      main: green[500],
      dark: green[700],
    },
    danger: {
      light: red[300],
      main: red[500],
      dark: red[700],
    },

    text: {
      primary: '#212353',
    },
    background: {
      defualt: '#fff',
    }

  },
  typography: {
    fontFamily: "'Roboto','Helvetica','sans-serif'",
    fontSize: 16,
    textTransform: 'none',
  },

  overrides: {
    MuiButton: {
      root: {
        borderRadius: '25px',
        paddingLeft: '24px',
        paddingRight: '24px',
        fontWeight: 600,
      },
    },
  },
};

const muiTheme = createTheme(theme);
export default muiTheme;
