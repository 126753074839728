import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import { NavBar } from '../components/Nav';
import { SideBar } from '../components/Nav';
import { Paper } from '@material-ui/core';

function Layout({ title, children }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const openSidebar = () => setIsSidebarOpen(true);
  const closeSidebar = () => setIsSidebarOpen(false);

  return (
    <BrowserRouter>
      <Paper style={{ height: '100vh' }}>
        <NavBar title={title}
          openSidebar={openSidebar}
          closeSidebar={closeSidebar}
          isSidebarOpen={isSidebarOpen}
          signout={() => Auth.signOut()}>
        </NavBar>
        {children}
        <SideBar
          isSidebarOpen={isSidebarOpen}
          closeSidebar={closeSidebar}
          openSidebar={openSidebar}
        ></SideBar>
      </Paper>
    </BrowserRouter>
  )
}

Layout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default Layout;