import React, { useState, useContext } from "react";
import UsersContext from "./UsersContext";
import { AppContext } from "./AppContext";

import {
  getUsers,
  createUser,
  updateUser as updateUserAPI,
  changeUserStatus,
  listUserAuthEvents,
  getUser,
} from "../utils/api/Users";

import {
  createTeam as createTeamAPI,
  deleteTeam as deleteTeamAPI
} from "../utils/api/Teams";
import { useEffect } from "react";

export function UsersProvider(props) {
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});
  const [userData, setUserData] = useState([]);
  const [teams, setTeams] = useState([]);
  const [hasMoreUsers, setHasMoreUsers] = useState(true);
  const [filters, setFilters] = useState({
    limit: "",
    PaginationToken: "",
  });
  const [checkedArray, setChecked] = useState([]);

  const {
    openSnackbar,
  } = useContext(AppContext);

  useEffect(() => {
    setChecked(users.slice().fill(false));
  }, [users]);

  const fetchUsers = async () => {
    try {
      setIsUsersLoading(true);
      if (hasMoreUsers) {
        const loadUsers = await getUsers(filters);
        console.log(loadUsers);
        setUsers(users.concat(loadUsers.items));
        setHasMoreUsers(!!(loadUsers && loadUsers.PaginationToken));
        setFilters({
          ...filters,
          PaginationToken:
            loadUsers && loadUsers.PaginationToken
              ? `${loadUsers.PaginationToken}`
              : "",
        });
      }
      setIsUsersLoading(false);
    } catch (error) {
      setIsUsersLoading(false);
      console.error(error);
      setHasMoreUsers(false);
    }
  };

  const fetchFilterUsers = async (searchText) => {
    try {
      setIsUsersLoading(true);
      const loadUsers = await getUsers(filters);
      if (searchText) {
        if (
          searchText.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ) {
          const filteredUsers = loadUsers.Users.filter((user) => {
            if (user && user.Attributes && user.Attributes.email) {
              return user.Attributes.email
                .toLowerCase()
                .includes(searchText.toLowerCase());
            }
            return false;
          });
          setUsers(filteredUsers);
        } else {
          const filteredUsers = loadUsers.Users.filter((user) => {
            if (user && user.Username) {
              return user.Username.toLowerCase().includes(
                searchText.toLowerCase()
              );
            }
            return false;
          });
          setUsers(filteredUsers);
        }
      } else {
        setUsers(loadUsers.Users);
      }
      setIsUsersLoading(false);
    } catch (error) {
      setIsUsersLoading(false);
      console.error(error);
    }
  };

  const fetchFilterStatusUsers = async (status) => {
    try {
      setIsUsersLoading(true);
      const loadUsers = await getUsers(filters);
      if (status === "all") {
        setUsers(loadUsers.Users);
      } else {
        const filteredUsers = loadUsers.Users.filter((user) => {
          return user.Enabled === status;
        });
        setUsers(filteredUsers);
      }
      setIsUsersLoading(false);
    } catch (error) {
      setIsUsersLoading(false);
      console.error(error);
    }
  };

  const addUser = async (user) => {
    const response = await createUser(user);
    if (response.errorMessage) {
      openSnackbar('Unable to add user', 'error');
      throw new Error(response.errorMessage);
    }
    setUsers((prevUsers) => [...prevUsers, user]);
    return response;
  };

  const updateUserStatus = async (username, status, index = null) => {
    const response = await changeUserStatus(username, status);
    if (response.errorMessage) {
      throw new Error(response.errorMessage);
    }
    if (index != null) {
      let updatedArray = [...users];
      updatedArray[index].enabled = status === "enable" ? true : false;
      setUsers(updatedArray);
    }
  };

  const handleListUserAuthEvents = async (username) => {
    try {
      const response = await listUserAuthEvents(username);
      setUserData(response.AuthEvents);
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleGetUser = async (username) => {
    try {
      const user = await getUser(username);
      setUser(user.item);
    } catch (error) {
      console.error("error", error);
    }
  };

  const addTeam = async (team) => {
    const response = await createTeamAPI(team);
    if (response.errorMessage) {
      openSnackbar('Unable to create team', 'error');
      throw new Error(response.errorMessage);
    }
    setTeams((prevUsers) => [...prevUsers, team]);
    return response;
  };

  const assignUserToTeam = async (teamId, username) => {
    console.log(username, teamId)
    const response = await updateUserAPI(username, { teamId });
    if (response.errorMessage) {
      throw new Error(response.errorMessage);
    }
    setTeams((prevUsers) => [...prevUsers, user]);
  };

  const removeUserFromTeam = async (teamId, username) => {
    console.log(username, teamId)
    const response = await updateUserAPI(username, { teamId: 'none' });
    if (response.errorMessage) {
      throw new Error(response.errorMessage);
    }
    setTeams((prevUsers) => [...prevUsers, user]);
  };

  const removeTeam = async (team) => {
    const response = await deleteTeamAPI(team.id);
    if (response.errorMessage) {
      openSnackbar('Unable to add user', 'error');
      throw new Error(response.errorMessage);
    }
    setTeams((prevUsers) => [...prevUsers, team]);
    return response;
  }


  return (
    <UsersContext.Provider
      value={{
        isUsersLoading,
        users,
        user,
        userData,
        checkedArray,
        fetchUsers,
        fetchFilterUsers,
        addUser,
        updateUserStatus,
        setChecked,
        setUserData,
        setUser,
        setUsers,
        handleListUserAuthEvents,
        handleGetUser,
        fetchFilterStatusUsers,
        assignUserToTeam,
        addTeam,
        removeTeam,
        removeUserFromTeam,
        teams,
      }}
    >
      {props.children}
    </UsersContext.Provider>
  );
}

export default UsersProvider;
