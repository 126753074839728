import Auth from "@aws-amplify/auth";

async function getIdToken() {
  try {
    const response = await Auth.currentSession();
    return response.idToken.jwtToken;
  } catch (error) {
    console.error("error: ", error);
  }
}

async function getIdTokenPayload() {
  try {
    const response = await Auth.currentSession();
    return response.idToken;
  } catch (error) {
    console.error("error: ", error);
  }
}

export { getIdToken, getIdTokenPayload };
