import React from 'react';
import UsersContextProvider from '../context/UsersContextProvider';
import { AppContextProvider } from '../context/AppContextProvider';

export default function withRoot(Component) {
    return function WrapperComponent(props) {
        return (
            <AppContextProvider>
                <UsersContextProvider>
                    <Component {...props} />
                </UsersContextProvider>
            </AppContextProvider>
        );
    };
}