import React from 'react';


export const AppContext = React.createContext({
    loading: false,
    startLoading: () => {},
    stopLoading: () => {},
    isSnackbarOpen: false,
    snackbarMessage: '',
    snackbarType: '',
    openSnackbar: () => {},
    closeSnackbar: () => {},
});

export const AppContextConsumer = AppContext.Consumer;
