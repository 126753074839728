import { withAuthenticator } from 'aws-amplify-react-extended';
import AmplifyTheme from '../themes/Amplify-UI';

const signUpConfig = {
  hideAllDefaults: true,
  signUpFields: [
    {
      label: 'Email',
      key: 'username',
      required: true,
      placeholder: 'Email',
      type: 'email',
      displayOrder: 1,
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      placeholder: 'Password',
      type: 'password',
      helperText: 'Passwords must be a minimum 10 characters in length',
      displayOrder: 4,
    },
  ],
};

const withAuth = (component) => (
  withAuthenticator(
    component,
    false, [],
    null,
    AmplifyTheme,
    signUpConfig,
    'email',
    'material',
    true,
    ['SignUp']
  )
);

export default withAuth;
