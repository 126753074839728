import { poolId, poolClientId } from './params';

const cognitoConfig = {
    // Amazon Cognito Region
    region: 'eu-west-1',
    // Amazon Cognito User Pool ID
    userPoolId: poolId,
    // Amazon Cognito Web Client ID
    userPoolWebClientId: poolClientId,
    mandatorySignIn: true,
    authEnabled: true,   // Setting this flag to false bypasses Cognito for convenience in dev
  };
  
  const config = {
    appTitle: 'AlgaSat Admin App',
    cognito: cognitoConfig,
    API: process.env.REACT_APP_API,
  };
  
  export default config;
  