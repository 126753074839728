import React from 'react';
import { Home, Settings, Person, Group } from '@material-ui/icons';

const NavLinks = {
  sideNav: [
    {
      name: "Home",
      url: "/dashboard",
      icon: <Home />,
    },
    {
      name: "Users",
      url: "/users",
      icon: <Person />,
    },
    {
      name: "Teams",
      url: "/teams",
      icon: <Group />,
    },
    {
      name: "Settings",
      url: "/settings",
      icon: <Settings />,
    },
  ]
};

export default NavLinks;
