import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, IconButton, makeStyles, Typography, Toolbar } from '@material-ui/core';
import { Menu as MenuIcon, ExitToApp as ExitIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  exitButton: {
    marginLeft:'auto',
    marginRight: theme.spacing(-2),
  }
}));

function NavBar({ title, openSidebar, closeSidebar, signout }) {
  const classes = useStyles();
  return (
    <AppBar position="sticky">
      <Toolbar>
        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu"
          onClick={() => openSidebar()}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        <IconButton className={classes.exitButton} onClick={signout}>
          <ExitIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

NavBar.propTypes = {
  signout: PropTypes.func,
  openSidebar: PropTypes.func,
  closeSidebar: PropTypes.func,
  title: PropTypes.string,
};

export default NavBar;