import React, { useState } from "react";
import { AppContext } from "./AppContext";

export const AppContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('info');


  const startLoading = () => {
    setLoading(true);
  };

  const stopLoading = () => {
    setLoading(false);
  };

  const closeSnackbar = () => {
    setIsSnackbarOpen(false);
  };

  const openSnackbar = (messageParam, typeParam) => {
    setSnackbarMessage(messageParam);
    setSnackbarType(typeParam);
    setIsSnackbarOpen(true);
  };

  return (
    <AppContext.Provider
      value={{
        loading,
        startLoading,
        stopLoading,
        isSnackbarOpen,
        snackbarMessage,
        snackbarType,
        openSnackbar,
        closeSnackbar,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
