import queryString from 'query-string';
import { getIdToken } from '../userAuth';
import config from '../../config';

const { API } = config;

export function getUser(id) {
return getIdToken()
    .then((token) => token)
    .then((token) => fetch(`${API}/users/${id}`,
    {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        Authorization: token,
        },
    }))
    .then((response) => {
    if (response.status === 200) {
        return response.json();
    }
    throw Error(`${response.status} ${response.statusText}`);
    });
}

export function listUserAuthEvents(id) {
    return getIdToken()
      .then((token) => token)
      .then((token) => fetch(`${API}/users/${id}/auth-events`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
        }))
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw Error(`${response.status} ${response.statusText}`);
      });
}

export function createUser(user) {
    return getIdToken()
      .then((token) => token)
      .then((token) => fetch(`${API}/users/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify(user),
        }))
      .then((response) => {
        if (response.status === 201) {
          return response.json();
        }
        throw Error(`${response.status} ${response.statusText}`);
      }).catch((err) => {
        console.log(err);
      });
}

export function updateUser(id, body) {
    return getIdToken()
      .then((token) => token)
      .then((token) => fetch(`${API}/users/${id}`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify(body),
        }))
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw Error(`${response.status} ${response.statusText}`);
      });
}
  

export function deleteUser(id) {
    return getIdToken()
      .then((token) => token)
      .then((token) => fetch(`${API}/users/${id}?force=true`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
        }))
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw Error(`${response.status} ${response.statusText}`);
      });
}
  
export function changeUserStatus(id, status) {
    return getIdToken()
      .then((token) => token)
      .then((token) => fetch(`${API}/users/${id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify({
            status,
          }),
        }))
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw Error(`${response.status} ${response.statusText}`);
      });
}

export const getUsers = async (filters) => {
    const qString = queryString.stringify(filters);
    const token = await getIdToken();
  
    const response = await fetch(`${API}/users${qString ? `?${qString}` : ''}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      });
  
    const result = await response.json();
    return result;
};
  