import { getIdToken } from '../userAuth';
import config from '../../config';

const { API } = config;

export function getTeams() {
return getIdToken()
    .then((token) => token)
    .then((token) => fetch(`${API}/teams/`,
    {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        Authorization: token,
        },
    }))
    .then((response) => {
    if (response.status === 200) {
        return response.json();
    }
    throw Error(`${response.status} ${response.statusText}`);
    });
}

export function getTeam(id) {
    return getIdToken()
        .then((token) => token)
        .then((token) => fetch(`${API}/teams/${id}`,
        {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            Authorization: token,
            },
        }))
        .then((response) => {
        if (response.status === 200) {
            return response.json();
        }
        throw Error(`${response.status} ${response.statusText}`);
        });
    }

export function createTeam(team) {
    console.log(team);
    return getIdToken()
    .then((token) => token)
    .then((token) => fetch(`${API}/teams/`,
    {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(team),
    }))
    .then((response) => {
    if (response.status === 201) {
        return response.json();
    }
    throw Error(`${response.status} ${response.statusText}`);
    });
}

export function updateTeam(id, body) {
    return getIdToken()
    .then((token) => token)
    .then((token) => fetch(`${API}/teams/${id}`,
    {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(body),
    }))
    .then((response) => {
    if (response.status === 200) {
        return response.json();
    }
    throw Error(`${response.status} ${response.statusText}`);
    });
}

export function deleteTeam(teamId) {
    console.log(teamId);
    return getIdToken()
    .then((token) => token)
    .then((token) => fetch(`${API}/teams/${teamId}`,
    {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    }))
    .then((response) => {
    if (response.status === 200) {
        return response.json();
    }
    throw Error(`${response.status} ${response.statusText}`);
    });
}
  