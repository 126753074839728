import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import Layout from './Layout';
import withAuth from './hoc/withAuth';
import withRoot from './hoc/withRoot';
import routes from './routes.js';
import config from './config';
import { makeStyles } from '@material-ui/core/styles';

import './App.css';

const useStyles = makeStyles(() => ({
  spinner: {
    position: 'fixed',
    top: '50%',
    right: '50%',
    zIndex: 1500,
  },
}
));

const { cognito: {authEnabled} } = config;
const { appTitle } = config;

function App() {
  const classes = useStyles();
  const loading = () => <CircularProgress className={classes.spinner}  />;

  return (
    <Layout title={appTitle}>
      <Suspense fallback={loading()}>
        <Switch>
          {routes.map((route, idx) => (route.component ? (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              name={route.name}
              render={(newProps) => (
                <route.component
                  {...newProps}
                  name={route.name}
                />
              )}
            />
          ) : (null)))}
          <Redirect from="/" to="/dashboard"/>
        </Switch>
      </Suspense>  
    </Layout>
  );
} 

const appExport = authEnabled ? withRoot(withAuth(App)) : withRoot(App);
export default appExport;
