export const palette = {
  foregroundMain: '#f8f8f8',
  backgroundMain: '#009a99',
};

export const typography = {
  fontFamily: [
    'Roboto',
    'Helvetica',
    'sans-serif',
  ].join(','),
}
