import React from "react";
const Dashboard = React.lazy(() => import("./views/Dashboard"));
const Settings = React.lazy(() => import("./views/Settings"));
const Users = React.lazy(() => import("./views/Users"));
const Activity = React.lazy(() => import("./views/Activity"));
const Teams = React.lazy(() => import("./views/Teams"));
const TeamDetails = React.lazy(() => import("./views/TeamDetails"));

const routes = [
  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  { path: "/settings", name: "Settings", component: Settings },
  { path: "/users", name: "Users", component: Users },
  { path: "/activity/member/:memberId", name: "Member", component: Activity },
  { path: "/teams", name: "Teams", component: Teams },
  { path: "/team/:teamId", name: "TeamDetails", component: TeamDetails },
];

export default routes;
